import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        
        <p>Welcome to Manu's page</p>
         <p>
        Thank you for visiting. Email me at manu.alias.dev@gmail.com 
        </p>


        <p>This Website is under construction</p>
      
      </header>
    </div>
  );
}

export default App;
